import axios from 'axios'

const forms = {

  namespaced: true,

  state: {
    status: 'unsent'
  },

  mutations: {
    updateStatus (state, status) {
      switch (status) {
        case 'start':
          state.status = 'sending'
          break
        case 200:
          state.status = 'sent'
          break
        default:
          state.status = 'error'
          break
      }
    }
  },

  actions: {
    async submitForm ({ commit }, form) {
      commit('updateStatus', 'start') /** START */
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      await axios
        .post('http://formsubmit.co/ajax/pg.softcode@gmail.com/', form)
        .then((response) => {
          commit('updateStatus', response.status) /** SUCCESS */
        })
        .catch((error) => {
          console.log(error)
          commit('updateStatus', error) /** ERROR */
        })
    }
  },

  getters: {
    getStatus (state) {
      return state.status
    }
  }

}

export default forms
